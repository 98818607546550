import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import axios from '../../../services/axios';
import { useNavigate } from 'react-router-dom';
import { DateObject } from "react-multi-date-picker";

export const LeaveApplicationLayout = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    //console.log(props.leave);
    const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);

    const sampleText = "";
    const sampleText2 = sampleText.replaceAll(' ', '')

    const fSize = 12;
    const marginTop = "20mm";
    const marginRight = "20mm";
    const marginBottom = "20mm";
    const marginLeft = "20mm";

    const summarizeDates = (dates) => {
        let dStr = "";
        let curM = null;
        let curD = null;
        let curY = null;
        let fD = null;
        let m, d, y = null;
        dates.map(date => {
            let dtC = new DateObject(date);
            m = dtC.month.shortName;
            d = dtC.day;
            y = dtC.year;

            if (curM !== m) {
                if (curM !== null) {
                    if (curD === null) {
                        dStr += d;
                        fD = d;
                    }
                    if ((curD !== (d - 1)) && (curD !== null)) {
                        if (curD !== fD && curD !== (d - 1)) {
                            dStr += `-${curD}`;
                        }
                        fD = d;
                    }
                }

                if (curM !== null) {
                    dStr += `/${curY} | `;
                }

                dStr += `${m}/`;
                dStr += `${d}`;

                curD = d;
                curM = m;
                curY = y;
                if (fD === null) {
                    fD = d;
                }
            } else {
                if (curD === null) {
                    dStr += d;
                    fD = d;
                }

                if ((curD !== (d - 1)) && curD !== null) {
                    if (curD !== fD) {
                        dStr += `-${curD}`;
                    }
                    dStr += `,${d}`;
                    fD = d;
                }
                curD = d;
            }
        });

        if (curD !== fD && fD !== null) {
            dStr += `-${d}`;
        }
        dStr += `/${y}`;

        return dStr;
    }

    function formatCurrency(salary) {
        let [sal, con] = salary.split("/");
        let [intPart, decPart] = sal.split(".");
        intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let salaryNum = decPart !== undefined ? intPart + "." + decPart : intPart;
        if (con !== undefined) {
            return "P " + salaryNum + " / " + con;
        } else {
            return "P " + salaryNum;
        }
    }

    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    useEffect(() => {
        fetchLeaveApplicationOptions()
    }, [])

    const fetchLeaveApplicationOptions = async () => {
        await axios.get(`/api/leaveapplication/options`).then(({ data }) => {
            setLeaveTypeOptions(data.types);
        })
    }

    function limitDecimals(num, maxDecimals) {
        const decimalPart = num.toString().split('.')[1];
        if (decimalPart && decimalPart.length > maxDecimals) {
            const factor = Math.pow(10, maxDecimals);
            return Math.round(num * factor) / factor;
        }
        return num;
    }

    return (
        <div ref={ref} style={{ fontSize: `${fSize}pt` }}>
            {/* <style nonce="hris-style">{getPageMargins()}</style> */}
            <Row className="mb-4 pt-0 mt-0">
                <Col className="">
                    <Row>
                        <Col>
                            <Form.Text className="text-dark font-italic">Civil Service Form No.6<br />Revised 2020</Form.Text>
                        </Col>
                        <Col className="text-end">
                            <Image
                                style={{
                                    maxWidth: "80%",
                                }}
                                src="https://upload.wikimedia.org/wikipedia/commons/4/4d/Official_Seal_of_Pangasinan.svg"
                                roundedCircle
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="text-center" style={{ fontSize: `${fSize + 1}pt` }}>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark fw-bold">&nbsp;</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark fw-bold">Republic of the Philippines</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark font-italic fw-bold">Provincial Government of Pangasinan</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark font-italic fw-bold">Lingayen</Form.Text>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            {/* <Image
                                style={{
                                    maxWidth: "40%",
                                }}
                                src="https://i.insider.com/5e820b04671de06758588fb8?width=700"
                                roundedCircle
                            /> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="">
                <Col className="text-center">
                    <h4 className="">APPLICATION FOR LEAVE</h4>
                </Col>
            </Row>
            <Row className="border border-1 border-dark text-dark m-0">
                <Col sm={4}>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">1. OFFICE/DEPARTMENT</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">{props.leave.office.offdesc || ""}</Form.Text>
                        </Col>
                    </Row>
                </Col>
                <Col sm={8}>
                    <Row>
                        <Col sm="auto">
                            <Form.Text className="text-dark">2. NAME</Form.Text>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Form.Text className="text-dark">(Last)</Form.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Text className="text-dark">{props.leave.plantilla.SURNAME}</Form.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Form.Text className="text-dark">(First)</Form.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Text className="text-dark">{props.leave.plantilla.FINAME}</Form.Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Form.Text className="text-dark">(Middle)</Form.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Text className="text-dark">{props.leave.plantilla.MIDNAME}</Form.Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="border-start border-bottom border-end border-1 border-dark pb-1 m-0">
                <Col sm={4}>
                    <Row>
                        <Col sm="auto">
                            <Form.Text className="text-dark">3. DATE OF FILING</Form.Text>
                        </Col>
                        <Col className="border-bottom border-1 border-dark" sm="6">
                            <Form.Text className="text-dark">&nbsp;{new DateObject(props.leave.created_at).format("MMMM/DD/YYYY")}</Form.Text>
                        </Col>
                    </Row>
                </Col>
                <Col sm={5}>
                    <Row>
                        <Col className="" sm="auto">
                            <Form.Text className="text-dark">4. POSITION</Form.Text>
                        </Col>
                        <Col className="border-bottom border-1 border-dark" sm="auto">
                            <Form.Text className="text-dark" sm="auto">{props.leave.position}</Form.Text>
                        </Col>
                    </Row>
                </Col>
                <Col sm={3}>
                    <Row>
                        <Col sm="auto">
                            <Form.Text className="text-dark">5. SALARY</Form.Text>
                        </Col>
                        <Col className="border-bottom border-1 border-dark" sm="6">
                            <Form.Text className="text-dark">{formatCurrency(props.leave.salary)}</Form.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="border-start border-bottom border-end border-1 border-dark text-center m-0">
                <Col sm={12}>
                    <Form.Label className="text-dark p-0 m-0">6. DETAILS OF APPLICATION</Form.Label>
                </Col>
            </Row>
            <Row className="border-start border-bottom border-end border-1 border-dark m-0">
                <Col className="border-end border-dark border-1" sm={6}>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">6.A TYPE OF LEAVE TO BE AVAILED</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {leaveTypeOptions.map((type) => (
                                //<Form.Check key={type.id} type="checkbox" value={type.id} selected={type.id == selectedType}>{`${type.type} (${type.executive_order})`}</Form.Check>
                                !type.type.includes("Others") ?
                                    <div className="form-check" key={type.id}>
                                        <input type="checkbox" className="form-check-input" id={`chkboxtype${type.id}`} checked={type.id === props.leave.leave_type_id} readOnly />
                                        <label style={{ fontSize: `${fSize - 1}pt` }} className="form-check-label" htmlFor={`chkboxtype${type.id}`}>{`${type.type} `}<label style={{ fontSize: `${fSize - 5}pt`, fontWeight: "normal" }}>{`(${type.executive_order})`}</label></label>
                                    </div>
                                    : null
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">Others</Form.Text>
                        </Col>
                    </Row>
                    <Row className="pb-2">
                        <Col className="border-bottom border-1 border-dark ms-3 me-3">
                            <Form.Text className="text-dark">
                                <span>
                                    {`${(props.leave.leave_type_id === 14 || props.leave.leave_type_id === 15 || props.leave.leave_type_id === 16) ? props.leave.leave_type.type.replace("Others - ", "") : ""}`}&nbsp;
                                </span>
                            </Form.Text>
                        </Col>
                    </Row>
                </Col>
                <Col sm={"6"}>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">6.B DETAILS OF LEAVE</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="font-italic text-dark">In case of Vacation/Special Privilege Leave:</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox1" checked={props.leave.leave_detail_id === 1 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox1">Within the Philippines</label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-dark ms-3 me-3">
                            <p className="m-0 p-0">
                                &nbsp;{props.leave.leave_detail_id === 1 ? props.leave.detail_description : ""}
                            </p>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox2" checked={props.leave.leave_detail_id === 2 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox2">Abroad (Specify)</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="border-bottom border-dark ms-3 me-3">
                            <p className="m-0 p-0">
                                &nbsp;{props.leave.leave_detail_id === 2 ? props.leave.detail_description : ""}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="font-italic text-dark">In case of Sick Leave:</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox3" checked={props.leave.leave_detail_id === 3 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox3">In Hospital (Specify Illness)</label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-dark ms-3 me-3">
                            <p className="m-0 p-0">
                                &nbsp;{props.leave.leave_detail_id === 3 ? props.leave.detail_description : ""}
                            </p>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox4" checked={props.leave.leave_detail_id === 4 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox4">Out Hospital (Specify Illness)</label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-dark ms-3 me-3">
                            <p className="m-0 p-0">
                                &nbsp;{props.leave.leave_detail_id === 4 ? props.leave.detail_description : ""}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="font-italic text-dark">In case of Special Leave Benefits for Women (Specify Illness):</Form.Text>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="border-bottom border-dark ms-3 me-3">
                            <p className="m-0 p-0">
                                &nbsp;{props.leave.leave_detail_id === 5 ? props.leave.detail_description : ""}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="font-italic text-dark">In case of Study Leave:</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox5" checked={props.leave.leave_detail_id === 6 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox5">Completion of Master's Degree</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox6" checked={props.leave.leave_detail_id === 7 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox6">BAR/board Examination Review</label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="font-italic text-dark">Other Purpose:</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox7" checked={props.leave.leave_detail_id === 8 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox7">Monetization of Leave Credits</label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox8" checked={props.leave.leave_detail_id === 9 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox8">Terminal Leave</label>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="border-start border-bottom border-end border-1 border-dark m-0">
                <Col className="border-end border-1 border-dark" sm={6}>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">6.C NUMBER OF WORKING DAYS</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-dark ms-3 me-3">
                            <Form.Text className="text-dark">&nbsp;{parseFloat(props.leave.no_days)}{props.leave.leave_type_id === 14 ? ` (${parseFloat(props.leave.no_days * 8)} Hours)` : ""}</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">INCLUSIVE DATES</Form.Text>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="border-bottom border-dark ms-3 me-3">
                            <Form.Text className="text-dark">&nbsp;
                                {/* {props.leave.leave_date.map((date, i, { length }) => (
                                    <span key={i}>
                                        {`${new DateObject(date).format("MM/DD/YYYY")}${length - 1 === i ? "" : ", "}`}
                                    </span>
                                ))} */}
                                {summarizeDates(props.leave.leave_date)}
                            </Form.Text>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">6.D COMMUTATION</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox9" checked={props.leave.commutation === 0 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox9">Not Requested</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox10" checked={props.leave.commutation === 1 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox10">Requested</label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            {/* <Form.Text className="text-dark fw-bold">{`${props.leave.plantilla.FINAME} ${props.leave.plantilla.MIDNAME} ${props.leave.plantilla.SURNAME}`.toUpperCase()}</Form.Text>
                            {props.leave.position} */}
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-top border-1 border-dark text-center ms-3 me-3 mb-2">
                            <Form.Text className="text-dark">(Signature of Applicant)</Form.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="border-start border-bottom border-end border-1 border-dark text-center m-0">
                <Col sm={12}>
                    <Form.Label className="text-dark p-0 m-0">7. DETAILS OF ACTION ON APPLICATION</Form.Label>
                </Col>
            </Row>
            <Row className="border-start border-bottom border-end border-1 border-dark m-0">
                <Col className="border-end border-1 border-dark" sm={6}>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">7.A CERTIFICATION OF LEAVE CREDITS</Form.Text>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col className="text-end" sm="4">
                            <Form.Text className="text-dark">As of</Form.Text>
                        </Col>
                        <Col className="border-bottom border-1 border-dark text-center" sm="5">
                            <Form.Text className="text-dark">&nbsp;{new DateObject(props.leave.created_at).subtract(1, "month").format("MMMM YYYY")}</Form.Text>
                        </Col>
                    </Row>
                    <Row className="text-center border border-1 border-dark ms-2 me-2">
                        <Col className="border-end border-1 border-dark"></Col>
                        <Col className="border-end border-1 border-dark">
                            <Form.Text className="text-dark fw-bold">Vacation Leave</Form.Text>
                        </Col>
                        <Col className="">
                            <Form.Text className="text-dark fw-bold">Sick Leave</Form.Text>
                        </Col>
                    </Row>
                    <Row className="text-center border-start border-end border-bottom border-1 border-dark ms-2 me-2">
                        <Col className="border-end border-1 border-dark font-italic">
                            <Form.Text className="text-dark pb-1 pt-1">Total Earned</Form.Text>
                        </Col>
                        <Col className="border-end  border-1 border-dark">{parseFloat(props.leave.vl_total)}</Col>
                        <Col>{parseFloat(props.leave.sl_total)}</Col>
                    </Row>
                    <Row className="text-center border-start border-end border-bottom border-1 border-dark ms-2 me-2">
                        <Col className="border-end border-1 border-dark font-italic">
                            <Form.Text className="text-dark pb-1 pt-1">Less this application</Form.Text>
                        </Col>
                        <Col className="border-end border-1 border-dark">{parseFloat(props.leave.less_vl)}</Col>
                        <Col>{parseFloat(props.leave.less_sl)}</Col>
                    </Row>
                    <Row className="text-center border-start border-end border-bottom border-1 border-dark mb-3 ms-2 me-2">
                        <Col className="border-end border-1 border-dark font-italic">
                            <Form.Text className="text-dark pb-1 pt-1">Balance</Form.Text>
                        </Col>
                        <Col className="border-end border-1 border-dark">{limitDecimals(parseFloat(props.leave.vl_total - props.leave.less_vl), 3)}</Col>
                        <Col>{limitDecimals(parseFloat(props.leave.sl_total - props.leave.less_sl), 3)}</Col>
                    </Row>
                    <Row className="text-center mb-1">
                        <Col>
                            <Form.Text className="text-dark fw-bold">
                                {!(props.leave.office.offcode == 101101) ? props.leave.ao_assign ?
                                    `${props.leave.ao_assign.plantilla.salutation ? props.leave.ao_assign.plantilla.salutation + " " : ""}
                                    ${props.leave.ao_assign.plantilla.FINAME} ${props.leave.ao_assign.plantilla.MIDNAME.split(" ").map(word => word[0].toUpperCase() + ".").join("")} ${props.leave.ao_assign.plantilla.SURNAME}`.toUpperCase()
                                    : <br />
                                    : "LYKA JOY B. SANCHEZ"}
                            </Form.Text>
                            {!(props.leave.office.offcode == 101101) ? props.leave.ao_assign ? props.leave.ao_assign.position_title : <br /> : "Community Affairs Assistant II"}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-top border-1 border-dark text-center mb-2 ms-3 me-3">
                            <Form.Text className="text-dark">(Authorized Officer)</Form.Text>
                        </Col>
                    </Row>
                </Col>

                <Col className="" sm={6}>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">7.B RECOMMENDATION</Form.Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox1" checked={props.leave.office_approved === 0 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox1">For approval</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col sm="auto">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkbox2" checked={props.leave.office_approved === 0 ? true : false} readOnly />
                                <label className="form-check-label" htmlFor="chkbox2">For disapproval due to</label>
                            </div>
                        </Col>
                        <Col className="border-bottom border-dark me-3">
                            <Form.Text className="text-dark">
                                &nbsp;{props.leave.off_dis_reason.length > 40 ? props.leave.off_dis_reason.substring(0, 40) : props.leave.off_dis_reason}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-dark ms-3 me-3 mb-2">
                            <Form.Text className="text-dark">
                                &nbsp;{props.leave.off_dis_reason.length > 40 ? props.leave.off_dis_reason.substring(40, 105) : props.leave.off_dis_reason}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-dark ms-3 me-3 mb-2">
                            <Form.Text className="text-dark">
                                &nbsp;{props.leave.off_dis_reason.length > 105 ? props.leave.off_dis_reason.length > 170 ? props.leave.off_dis_reason.substring(105, 170) : props.leave.off_dis_reason.substring(75, props.leave.off_dis_reason.length) : ""}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ paddingBottom: `4pt` }} className="border-bottom border-dark ms-3 me-3 mb-4">
                            <Form.Text className="text-dark">
                                &nbsp;{props.leave.off_dis_reason.length > 170 ? props.leave.off_dis_reason.substring(170, 235) : ""}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            <Form.Text className="text-dark fw-bold">
                                {!(props.leave.office.offcode == 101101) ? props.leave.off_assign ?
                                    `${props.leave.off_assign.plantilla.salutation ? props.leave.off_assign.plantilla.salutation + " " : ""}
                                ${props.leave.off_assign.plantilla.FINAME} ${props.leave.off_assign.plantilla.MIDNAME.split(" ").map(word => word[0].toUpperCase() + ".").join("")} ${props.leave.off_assign.plantilla.SURNAME}`.toUpperCase()
                                    : <br />
                                    : "RICHARD A. DIZON, DPA"}
                            </Form.Text>
                            {!(props.leave.office.offcode == 101101) ? props.leave.off_assign ? props.leave.off_assign.position_title : <br /> : "Project Evaluation Officer III"}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-top border-1 border-dark text-center float-bottom ms-3 me-3">
                            <Form.Text className="text-dark">(Authorized Officer)</Form.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="border-start border-end border-1 border-dark m-0 pb-5">
                <Col className="" sm={6}>
                    <Row>
                        <Col>
                            <Form.Text className="text-dark">7.C APPROVED FOR</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-1 border-dark ms-4 text-center" sm="2">
                            <Form.Text className="text-dark">{props.leave.leave_type_id !== 14 ? parseFloat(props.leave.days_w_pay) : 0}</Form.Text>
                        </Col>
                        <Col className="ps-1">
                            <Form.Text className="text-dark">days with pay</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-1 border-dark ms-4 text-center" sm="2">
                            <Form.Text className="text-dark">{parseFloat(props.leave.days_wo_pay)}</Form.Text>
                        </Col>
                        <Col className="ps-1">
                            <Form.Text className="text-dark">days without pay</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-1 border-dark ms-4 text-center" sm="2">
                            <Form.Text className="text-dark">
                                {props.leave.leave_type_id !== 14 ? parseFloat(props.leave.others_pay) : `${parseFloat(props.leave.days_w_pay * 8)} Hours`}
                            </Form.Text>
                        </Col>
                        <Col className="ps-1">
                            <Form.Text className="text-dark">others (Specify)<u>{` ${props.leave.leave_type_id !== 14 ? (props.leave.others_pay_spec || "") : "CTO "}`}</u></Form.Text>
                        </Col>
                    </Row>
                </Col>
                <Col className="" sm={6}>
                    <Row>
                        <Col>
                            <   Form.Text className="text-dark">7.D DISAPPROVED DUE TO</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-dark ms-3 me-3 mb-2">
                            <Form.Text className="text-dark">
                                &nbsp;{props.leave.pgo_dis_reason.length > 65 ? props.leave.pgo_dis_reason.substring(0, 65) : props.leave.pgo_dis_reason}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-dark ms-3 me-3 mb-2">
                            <Form.Text className="text-dark">
                                &nbsp;{props.leave.pgo_dis_reason.length > 65 ? props.leave.pgo_dis_reason.length > 130 ? props.leave.pgo_dis_reason.substring(65, 130) : props.leave.pgo_dis_reason.substring(75, props.leave.pgo_dis_reason.length) : ""}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-dark ms-3 me-3 mb-3">
                            <Form.Text className="text-dark">
                                &nbsp;{props.leave.pgo_dis_reason.length > 130 ? props.leave.pgo_dis_reason.substring(130, 195) : ""}
                            </Form.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="border-start border-bottom border-end border-1 border-dark m-0">
                <Col>

                </Col>
                <Col sm="6">
                    <Row>
                        <Col className="text-center float-bottom ms-3 me-3 text-dark fw-bold">
                            APPROVED For and By Authority of the Governor
                            <br /><br />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center float-bottom ms-3 me-3">
                            <Form.Text className="border-bottom border-1 border-dark text-dark fw-bold">
                                {/* {props.leave.pgo_assign ? `${props.leave.pgo_assign.plantilla.salutation ? props.leave.pgo_assign.plantilla.salutation+" " : ""}
                                    ${props.leave.pgo_assign.plantilla.FINAME} ${props.leave.pgo_assign.plantilla.MIDNAME.split(" ").map(word => word[0].toUpperCase()+".").join("")} ${props.leave.pgo_assign.plantilla.SURNAME}`.toUpperCase()
                                : <br/>} */}
                                {/* MR. MELICIO FLORES PATAGUE II */}
                                {!props.leave.forHead ? "ATTY. RONN DALE B. CASTILLO" : "MR. MELICIO FLORES PATAGUE II"}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mb-2">
                            {/* <Form.Text className="text-dark fw-bold">
                                {props.leave.pgo_assign ? props.leave.pgo_assign.position_title : <br />}
                            </Form.Text> */}
                            <Form.Text className="text-dark fw-bold">
                                {/* Provincial Administrator */}
                                {!props.leave.forHead ? "Executive Assistant III" : "Provincial Administrator"}
                            </Form.Text>
                        </Col>
                    </Row>
                </Col>
                <Col>
                </Col>
            </Row>
        </div>
    );
});