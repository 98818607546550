import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { appModules } from "./utilities/constants";
import { Header, NavBar, Login, Registration} from './components';
import AuthDocument from './components/AuthDocument/AuthDocument';
import axios from './services/axios';
import { useEffect, useState } from 'react';
import { Collapse } from "react-bootstrap";

const App = () => {
    let firstApp
    let [user, setUser] = useState({})
    let [isLoading, setIsLoading] = useState(true);
    let [currentApp, setCurrentApp] = useState("");
    // const [navVisible, setnavVisible] = useState(true);
    let userIsMobile = window.innerWidth <= 768;
    const [navVisible, setnavVisible] = useState(!userIsMobile);

    const togglenav = (visibility) =>{
        setnavVisible(visibility)
    }

    const check_token = async () => {
        await axios.get('/api/userInfo')
            .then((response) => {
                if (response.data?.modules?.length > 0) { response.data['firstApp'] = appModules.find(app => response.data?.modules[0]?.abilities[0]?.abilities_id === app.id) }
                //console.log(response.data);
                setUser(response.data)
                setIsLoading(false)
            })
            .catch((error) => {
                setUser({})
                setIsLoading(false)
            })
    }

    useEffect(() => {
        check_token()
    }, [])

    return (
        <BrowserRouter>
            {isLoading ? <div className="d-flex vh-100 vw-100">
                <div className="text-light m-auto"><pre>
                    ___________________________________________________________________________________________________________<br />
                    |           _____                     _____                     _____                     _____           |<br />
                    |          /\    \                   /\    \                   /\    \                   /\    \          |<br />
                    |         /::\____\                 /::\    \                 /::\    \                 /::\    \         |<br />
                    |        /:::/    /                /::::\    \                \:::\    \               /::::\    \        |<br />
                    |       /:::/    /                /::::::\    \                \:::\    \             /::::::\    \       |<br />
                    |      /:::/    /                /:::/\:::\    \                \:::\    \           /:::/\:::\    \      |<br />
                    |     /:::/____/                /:::/__\:::\    \                \:::\    \         /:::/__\:::\    \     |<br />
                    |    /::::\    \               /::::\   \:::\    \               /::::\    \        \:::\   \:::\    \    |<br />
                    |   /::::::\    \   _____     /::::::\   \:::\    \     ____    /::::::\    \     ___\:::\   \:::\    \   |<br />
                    |  /:::/\:::\    \ /\    \   /:::/\:::\   \:::\____\   /\   \  /:::/\:::\    \   /\   \:::\   \:::\    \  |<br />
                    | /:::/  \:::\    /::\____\ /:::/  \:::\   \:::|    | /::\   \/:::/  \:::\____\ /::\   \:::\   \:::\____\ |<br />
                    | \::/    \:::\  /:::/    / \::/   |::::\  /:::|____| \:::\  /:::/    \::/    / \:::\   \:::\   \::/    / |<br />
                    |  \/____/ \:::\/:::/    /   \/____|:::::\/:::/    /   \:::\/:::/    / \/____/   \:::\   \:::\   \/____/  |<br />
                    |           \::::::/    /          |:::::::::/    /     \::::::/    /             \:::\   \:::\    \      |<br />
                    |            \::::/    /           |::|\::::/    /       \::::/____/               \:::\   \:::\____\     |<br />
                    |            /:::/    /            |::| \::/____/         \:::\    \                \:::\  /:::/    /     |<br />
                    |           /:::/    /             |::|   |                \:::\    \                \:::\/:::/    /      |<br />
                    |          /:::/    /              |::|   |                 \:::\    \                \::::::/    /       |<br />
                    |         /:::/    /               \::|   |                  \:::\____\                \::::/    /        |<br />
                    |         \::/    /                 \:|   |                   \::/    /                 \::/    /         |<br />
                    |          \/____/                   \|___|                    \/____/                   \/____/          |<br />
                    |_________________________________________________________________________________________________________|<br />
                </pre></div></div> :
                <>
                        {user?.abilities?.length > 0 ?
                            <div className="d-flex flex-column vh-100 vw-100" >
                                <Header user={user} navVisible={navVisible} togglenav={togglenav}/>
                                <div className="d-flex" id="page-content" style={{ height: 'calc(100% - 50px)', width: '100%' }}>
                                    <Collapse in={navVisible} dimension="width">
                                        <div>
                                            <NavBar currentApp={currentApp} items={user.modules} isMobile={userIsMobile} setCurrentApp={setCurrentApp} />
                                        </div>
                                    </Collapse>
                                    <div className="" id="mainContent" style={{ height: '100%', overflow: 'auto'}}>
                                        <Routes>
                                            <Route path="*" element={user.firstApp.comp(user)} key={user.firstApp.url} />
                                            {appModules.map((app) => {
                                                        if (app.id===99) {
                                                            return <Route path={app.url} element={app.comp(user)} key={app.url} />
                                                        }
                                                        return null;
                                                    })}
                                            <Route path="/account" element={user.firstApp.comp(user)} key='/account' />
                                            {user.modules.map((item) => (
                                                item.abilities.map((ability) => (
                                                    appModules.map((app) => {
                                                        if (ability.abilities_id === app.id) {
                                                            if (app.id===4) {
                                                                return <Route path={app.url} element={app.comp(user, 'office')} key={app.url} />
                                                            }
                                                            if (app.id===6) {
                                                                return <Route path={app.url} element={app.comp(user, 'pgo')} key={app.url} />
                                                            }
                                                            return <Route path={app.url} element={app.comp(user)} key={app.url} />;
                                                        }
                                                        return null;
                                                    })
                                                )
                                                )))
                                            }
                                        </Routes>
                                    </div>
                                    {/* <Footer /> */}
                                </div>
                            </div> : <Routes>
                                <Route path="/login" element={<Login setUser={setUser} firstApp={firstApp} appModules={appModules} />} />
                                <Route path="/registration" element={<Registration />} />
                                <Route path="/authenticate/:documentcode" element={<AuthDocument />} />
                                <Route path="*" element={<Navigate to="/login" replace />} />
                            </Routes>
                        }

                    </>
            }
                </BrowserRouter>
            )
};

export default App