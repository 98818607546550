import React, { useEffect, useState } from 'react';
import axios from '../../services/axios';
import { useParams } from 'react-router-dom';

const AuthDocument = () => {
    let {documentcode} = useParams();
    const [validity,setValidity] = useState(0)
    const [document,setDocument] = useState(0)
    const verifydoc = async (code) => {
        try {
            
            const response = await axios.post(`/api/docverify`, {
                documentcode: decodeURIComponent(code)
            });
            if(response.data.Result[0]==='Invalid'){
                setValidity(2)
            } else {
                setValidity(1)
                setDocument(response.data.Result)
            }
            //console.log(response.data.Result);
        } catch (error) {
            console.error('Error verifying document:', error);
        }
    };

    const getMonthName = (monthNumber) => {
        if (monthNumber < 1 || monthNumber > 12) {
            return "";
        }
        const date = new Date(2024, monthNumber - 1, 1);
        return date.toLocaleString('default', { month: 'long' });
    };

    useEffect(() => {
            //console.log(decodeURIComponent(documentcode))
            verifydoc(documentcode);
    }, []);

  return (
    <div className="d-flex flex-row justify-content-center align-items-center" style={{ height: '100vh' }}>
        {validity===0 ? <div>Loading...</div> : 
            <div className="d-flex flex-column border border-primary rounded p-1" style={{ width: '350px', backgroundColor: 'white' }}>
                <div style={{ marginTop: 20, textAlign: 'center', fontWeight: 'bolder', color: 'teal', fontSize: '12pt', lineHeight: 1 }}>PROVINCIAL GOVERNMENT OF PANGASINAN</div>
                <div style={{ marginTop: 20, textAlign: 'center', fontWeight: 'bolder', color: 'teal', fontSize: '12pt', lineHeight: 1 }}>Document Verification System</div>
                <div className="d-flex flex-row justify-content-around p-4">
                    <div>
                        <img 
                            src="https://upload.wikimedia.org/wikipedia/commons/4/4d/Official_Seal_of_Pangasinan.svg" 
                            alt="Official Seal of Pangasinan" 
                            style={{ width: '2cm', height: '2cm' }} 
                        />
                    </div>
                    <div>
                        <img 
                            src={`/resources/images/${validity===2 ? 'un':''}verifieddocument.svg`}
                            alt="Verified" 
                            style={{ width: '2cm', height: '2cm' }} 
                        />
                    </div>
                </div>
                {validity===1 ? 
                    <div className="m-2" style={{ textAlign: 'center', fontWeight: 'bolder', color: 'blue', fontSize: '9pt' }}>VERIFIED OFFICIAL {document.document}</div> :
                    <div className="m-2" style={{ textAlign: 'center', fontWeight: 'bolder', color: 'red', fontSize: '9pt' }}>VERIFICATION FAILED</div>
                }
                
                {validity===2 && (
                    <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                        <div className="p-0" style={{ textAlign: 'center', fontSize: '8pt', lineHeight: .60 }}>NO RECORD</div>
                    </div>)} 
                
                {document.document==='LEAVE APPLICATION FORM' && 
                    <div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>Document ID</div>
                            <div className='p-0'>{document.id*85555556}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>ISSUED TO</div>
                            <div className='p-0'>{document.issuedto}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>DATE ISSUED</div>
                            <div className='p-0'>{document.dateissued}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>TYPE</div>
                            <div className='p-0'>{document.leavetype}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>INCLUSIVE DAY(S)</div>
                            <div className='p-0'>{document.days}</div>
                        </div>
                    </div>
                }

                {document.document==='TRAVEL ORDER' && 
                    <div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>Document ID</div>
                            <div className='p-0'>{document.id*85555556}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>ISSUED TO</div>
                            <div className='p-0'>{document.participants}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>DATE ISSUED</div>
                            <div className='p-0'>{document.dateissued}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>TYPE</div>
                            <div className='p-0'>{document.type===0 ? 'Official Business' : 'Official Time'}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>PURPOSE</div>
                            <div className='p-0'>{document.purpose}</div>
                        </div>
                    </div>
                }

                {document.document==='DAILY TIME RECORD' && 
                    <div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>Document ID</div>
                            <div className='p-0'>{document.id*85555556}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>ISSUED TO</div>
                            <div className='p-0'>{document.owner}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>DATE ISSUED</div>
                            <div className='p-0'>{document.dateissued}</div>
                        </div>
                        <div className="d-flex flex-column border border border-success ms-3 me-3 mb-1 p-1">
                            <div className="p-0" style={{ fontSize: '8pt', lineHeight: .60 }}>PERIOD</div>
                            <div className='p-0'>{getMonthName(document.month) + ' ' + document.year} {document.period===1 && ''} {document.period===2 && '(First Half)'} {document.period===3 && '(Second Half)'}</div>
                        </div>
                    </div>
                }




            </div>
        }
        
    </div>
  )
}

export default AuthDocument
