import MusicNoteIcon from '@mui/icons-material/MusicNote';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SchoolIcon from '@mui/icons-material/School';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';

import { FileEarmarkText, Alarm, Table, CardChecklist, ClockHistory, CarFront, VectorPen } from 'react-bootstrap-icons';

//Employee Services
import ESScheduleAndDTR from '../components/EmployeeServices/ScheduleAndDTR/Page.component';
import ESLeaveApplication from '../components/EmployeeServices/LeaveApplication/Page.component';
//HR
import HRLeaveApplication from '../components/HumanResource/LeaveApplication/Page.component';
//Accounting

//import ACCTravelOrder from '../components/Accounting/TravelOrder/Page.component';

//Office
import OFFDTRApproval from '../components/Office/DTRApproval/Page.component';
import OFFSchedApproval from '../components/Office/ScheduleApproval/Page.component';
import OFFEmployeeSchedule from '../components/Office/EmployeeSchedule/Page.component';
import OFFLeaveApplication from '../components/Office/LeaveApplication/Page.component';
import CTO from '../components/Office/CompensatoryTimeOff/Page.component';
import OFFTravelOrder from '../components/Office/TravelOrder/Page.component';
import ApprovelOfficePage from '../components/Office/ApprovedList/ApprovalPage.component';
import DivisionPage from '../components/Office/Divisions/Page.component';

//PGO
import PGOLeaveApplication from '../components/PGO/LeaveApplication/Page.component';
import PGOTravelOrder from '../components/PGO/TravelOrder/Page.component';

//ADMIN
import ADMINUserAccess from '../components/Admin/UserAccessManagement/Page.component';
import BioMonitoring from '../components/Admin/BiometricsMonitoring/Page.component';
import HolidayManage from '../components/Admin/HolidayManage/Page.component';
import TriggerAccess from '../components/Admin/TriggerAccess/Page.component';
import AdminEmployeeSchedule from '../components/Admin/EmployeeSchedule/Page.component';

//HRIS Modules
import SearchEmployee from '../components/HRIS/EmployeeSearch/pages/SearchEmployee';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

//Account
import Account from '../components/Account/Account'
export const logo = 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Official_Seal_of_Pangasinan_2017.png/598px-Official_Seal_of_Pangasinan_2017.png';

export const categories = [
    { name: 'New', icon: <HomeIcon />, },
    { name: 'JS Mastery', icon: <CodeIcon />, },
    { name: 'Coding', icon: <CodeIcon />, },
    { name: 'ReactJS', icon: <CodeIcon />, },
    { name: 'NextJS', icon: <CodeIcon />, },
    { name: 'Music', icon: <MusicNoteIcon /> },
    { name: 'Education', icon: <SchoolIcon />, },
    { name: 'Podcast', icon: <GraphicEqIcon />, },
    { name: 'Movie', icon: <OndemandVideoIcon />, },
    { name: 'Gaming', icon: <SportsEsportsIcon />, },
    { name: 'Live', icon: <LiveTvIcon />, },
    { name: 'Sport', icon: <FitnessCenterIcon />, },
    { name: 'Fashion', icon: <CheckroomIcon />, },
    { name: 'Beauty', icon: <FaceRetouchingNaturalIcon />, },
    { name: 'Comedy', icon: <TheaterComedyIcon />, },
    { name: 'Gym', icon: <FitnessCenterIcon />, },
    { name: 'Crypto', icon: <DeveloperModeIcon />, },
];

export const appModules = [
    //employee services routes
    { id: 1, icon: <FileEarmarkText size={20} />, url: '/employeeservices/leaveapplication', title: 'Leaves', section: 'emp', module: ['emp_leave_app'], comp: function (data) { return <ESLeaveApplication user={data} /> } },
    { id: 9, icon: <Alarm size={20} />, url: '/employeeservices/scheduleanddtr', title: 'Schedule and DTR', section: 'emp', module: ['emp_sched_dtr'], comp: function (data) { return <ESScheduleAndDTR user={data} /> } },

    //Attendance management
    { id: 3, icon: <Table size={20} />, url: '/office/employeeschedule', title: 'Office Attendance Approval', section: 'off', module: ['off_dtr_app'], comp: function (data) { return <OFFEmployeeSchedule user={data} /> } },
    { id: 7, icon: <CardChecklist size={20} />, url: '/office/leaveapplication', title: 'Office Leaves', section: 'off', module: ['off_leave_manage'], comp: function (data) { return <OFFLeaveApplication user={data} /> } },
    { id: 16, icon: <ClockHistory size={20} />, url: '/office/cto', title: 'Office COCs', section: 'off', module: ['off_leave_manage'], comp: function (data) { return <CTO user={data} /> } },
    { id: 5, icon: <CarFront size={20} />, url: '/office/travelorder', title: 'Office Travel Orders', section: 'off', module: ['off_to'], comp: function (data) { return <OFFTravelOrder user={data} /> } },
    { id: 4, icon: <VectorPen size={20} />, url: '/office/Approvals', title: 'List office Approval', section: 'off', module: ['off_dtr_app', 'off_leave_app', 'off_to_app'], comp: function (data, actor) { return <ApprovelOfficePage user={data} actor={actor} /> } },
    { id: 6, icon: <VectorPen size={20} />, url: '/office/PGOApprovals', title: 'List office Approval', section: 'off', module: ['off_dtr_app', 'off_leave_app', 'off_to_app'], comp: function (data, actor) { return <ApprovelOfficePage user={data} actor={actor} /> } },
    { id: 2, icon: <AssignmentTurnedInOutlinedIcon />, url: '/humanresource/leaveapplication', title: 'HR Leave Validation', section: 'hr', module: ['hr_leave_app'], comp: function (data) { return <HRLeaveApplication user={data} /> } },
    { id: 30, icon: <SportsEsportsIcon />, url: '/office/divisions', title: 'Office Divisions', section: 'off', module: ['trigger_access'], comp: function (data) { return <DivisionPage user={data} /> } },

    //human resource routes
    { id: 10, icon: <ApprovalOutlinedIcon />, url: '/pgo/leaveapplication', title: 'PGO Leave Approval', section: 'pgo', module: ['pgo_leave_app'], comp: function (data) { return <PGOLeaveApplication user={data} /> } },
    { id: 11, icon: <AirportShuttleIcon />, url: '/travelorder', title: 'Travel Orders', section: 'all', module: ['to_viewer'], comp: function (data) { return <PGOTravelOrder user={data} /> } },
    { id: 17, icon: <ApprovalOutlinedIcon />, url: '/employeesearch', title: 'Search Employee', section: 'pgo', module: ['pgo_leave_app'], comp: function (data) { return <SearchEmployee /> } },
    { id: 18, icon: <AirportShuttleIcon />, url: '/travelorder', title: 'Travel Orders', section: 'all', module: ['to_viewer'], comp: function (data) { return <PGOTravelOrder user={data} /> } },
    { id: 20, icon: <ApprovalOutlinedIcon />, url: '/pgo/leaveapplication', title: 'PGO Leave Approval', section: 'pgo', module: ['pgo_leave_app'], comp: function (data) { return <PGOLeaveApplication user={data} /> } },
    { id: 21, icon: <AirportShuttleIcon />, url: '/travelorder', title: 'Travel Orders', section: 'all', module: ['to_viewer'], comp: function (data) { return <PGOTravelOrder user={data} /> } },

    //admin
    { id: 27, icon: <SportsEsportsIcon />, url: '/admin/useraccess', title: 'User Access Management', section: 'admin', module: ['user_access_manager'], comp: function (data) { return <ADMINUserAccess user={data} /> } },
    { id: 24, icon: <SportsEsportsIcon />, url: '/admin/biometricsmonitoring', title: 'Biometrics Monitoring', section: 'admin', module: ['bio_monitoring'], comp: function (data) { return <BioMonitoring user={data} /> } },
    { id: 28, icon: <SportsEsportsIcon />, url: '/admin/holidays', title: 'Holiday Manager', section: 'admin', module: ['holiday_manager'], comp: function (data) { return <HolidayManage user={data} /> } },
    { id: 29, icon: <SportsEsportsIcon />, url: '/admin/triggers', title: 'Trigger Access', section: 'admin', module: ['trigger_access'], comp: function (data) { return <TriggerAccess user={data} /> } },
    { id: 31, icon: <SportsEsportsIcon />, url: '/admin/employeeschedule', title: 'Employee Schedule Admin Access', section: 'admin', module: ['employee_schedule_admin'], comp: function (data) { return <AdminEmployeeSchedule user={data} /> } },
    //account
    { id: 99, icon: <SportsEsportsIcon />, url: '/account', title: 'User Account Settings', section: 'admin', module: [''], comp: function (data) { return <Account user={data} /> } },
];