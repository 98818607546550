import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { DateObject } from "react-multi-date-picker";
import Form from 'react-bootstrap/Form'

export const AttendanceReport = React.forwardRef((props, ref) => {
    //console.log(props);
    const navigate = useNavigate();
    const noPages = Math.ceil(props.employees.length / 10);
    const loopArray = Array.from({ length: noPages });
    let count = 0;
    const fSize = 10;
    const rangeStart = 1;
    const rangeEnd = props.selectedDate.month.length;
    const month = props.selectedDate.monthIndex;
    const marginTop = "50px"
    const marginRight = "50px"
    const marginBottom = "50px"
    const marginLeft = "50px"

    const getPageMargins = () => {
        return `@page {
            size: landscape;
        }`;
    };

    const renderDayCol = (val = null, rSpan = 2) => {
        let days = [];
        if (val || val === "") {
            for (let i = 0; i < rangeEnd; i++) {
                days.push(
                    <td key={i} rowSpan={rSpan}>{val}</td>
                )
            }
        } else {
            for (let i = 0; i < rangeEnd; i++) {
                let day = i + 1;
                days.push(
                    <td key={i} rowSpan={rSpan}>{day}</td>
                )
            }
        }
        return days;
    }

    const renderReport = (page) => {
        let empRows = [];
        let empCount = 0;
        props.employees.forEach((emp, ekey) => {
            if (empCount < 10 && ekey >= page * 10) {
                count++;
                empCount++;
                let tdam = [];
                let tdpm = [];

                let vsplwp = 0;
                let slwp = 0;
                let dwop = 0;
                let dAbsent = 0;
                //let levded = 0; // minutes/60/8

                let lateC = 0;
                let utTot = 0;
                let curStat = "";
                let inKey = 0;
                let inmeridiem = "";
                let hasIn = false;
                let hasSchedIn = false;
                let amUT;
                let pmUT;
                let utArray = [];

                emp.schedules.forEach((sched, key) => {
                    utArray[key] = [];
                    lateC += sched.late_count;
                    let ut = sched.undertime.split(",").map(Number);
                    utTot += (ut[0] + ut[1] + ut[2] + ut[3]);
                    if (sched.sched_am_in !== "" && sched.sched_am_in !== null && !hasSchedIn) {
                        inKey = key;
                        inmeridiem = "amUT";
                        hasSchedIn = true;
                        if (sched.attend_am_in !== "") {
                            hasIn = true;
                            amUT = ut[0];
                        } else {
                            hasIn = false;
                            curStat = "A";
                            amUT = curStat;
                        }
                    } else {
                        if (hasSchedIn) {
                            amUT = curStat;
                        } else {
                            amUT = "";
                        }
                    }

                    if (sched.sched_am_out !== "" && sched.sched_am_out !== null && hasSchedIn) {
                        if (hasIn) {
                            if (sched.attend_am_out !== "") {
                                amUT += ut[1];
                            } else {
                                amUT = "A";
                                if (key !== 0) {
                                    utArray[inKey][inmeridiem] = "A";
                                }
                            }
                        } else {
                            amUT = curStat;
                            curStat = "";
                        }
                        hasIn = false;
                        hasSchedIn = false;
                    } else {
                        if (hasSchedIn) {
                            amUT = curStat;
                        } else {
                            amUT = "";
                        }
                    }

                    if (sched.sched_pm_in !== "" && sched.sched_pm_in !== null && !hasSchedIn) {
                        inKey = key;
                        inmeridiem = "pmUT";
                        hasSchedIn = true;
                        if (sched.attend_pm_in !== "") {
                            hasIn = true;
                            pmUT = ut[2];
                        } else {
                            hasIn = false;
                            curStat = "A";
                            pmUT = curStat;
                        }
                    } else {
                        if (hasSchedIn) {
                            pmUT = curStat;
                        } else {
                            pmUT = "";
                        }
                    }

                    if (sched.sched_pm_out !== "" && sched.sched_pm_out !== null && hasSchedIn) {
                        if (hasIn) {
                            if (sched.attend_pm_out !== "") {
                                pmUT = ut[2] + ut[3];
                            } else {
                                pmUT = "A";
                                if (key !== 0) {
                                    utArray[inKey][inmeridiem] = "A";
                                }
                            }
                        } else {
                            pmUT = curStat;
                            curStat = "";
                        }
                        hasIn = false;
                        hasSchedIn = false;
                    } else {
                        if (hasSchedIn) {
                            pmUT = curStat;
                        } else {
                            pmUT = "";
                        }
                    }

                    //tdam.push(<td key={key}>&nbsp;{amUT !== 0 ? amUT : ""}</td>);
                    //tdpm.push(<td key={key}>&nbsp;{pmUT !== 0 ? pmUT : ""}</td>);

                    let legends = ["VL", "FL", "SL", "OB", "SPL", "*SPL", "CTO", "A", "ML", "PL", "*SL", "VAWC", "SLBW", "RP", "CL", "AL", "CTO", "CQT", "CQTL"];

                    if (legends.includes(sched.attend_am_in)) {
                        amUT = sched.attend_am_in;
                    } else if (sched.attend_am_in === "HL" || sched.attend_am_in === "WS") {
                        amUT = "";
                    } if (legends.includes(sched.attend_am_out)) {
                        amUT = sched.attend_am_out;
                    } else if (sched.attend_am_out === "HL" || sched.attend_am_out === "WS") {
                        amUT = "";
                    }

                    if (legends.includes(sched.attend_pm_in)) {
                        pmUT = sched.attend_pm_in;
                    } else if (sched.attend_pm_in === "HL" || sched.attend_pm_in === "WS") {
                        pmUT = "";
                    } else if (legends.includes(sched.attend_pm_out)) {
                        pmUT = sched.attend_pm_out;
                    } else if (sched.attend_pm_out === "HL" || sched.attend_pm_out === "WS") {
                        pmUT = "";
                    }
                    if(amUT === "A" && pmUT === "A"){
                        dAbsent++;
                    }
                    utArray[key]["amUT"] = amUT;
                    utArray[key]["pmUT"] = pmUT;
                });

                utArray.forEach((under, key) => {
                    tdam.push(<td key={key}>&nbsp;{under.amUT !== 0 ? under.amUT : ""}</td>);
                    tdpm.push(<td key={key}>&nbsp;{under.pmUT !== 0 ? under.pmUT : ""}</td>);
                });

                emp.leaveApps.forEach((leave, key) => {
                    let days_w_pay = parseFloat(leave.days_w_pay);
                    //let days_wo_pay = parseFloat(leave.days_wo_pay);
                    let actDays_w_pay = 0;
                    let actDays_wo_pay = 0;
                    let usedDays_w_pay = 0;
                    let usedDays_wo_pay = 0;
                    leave.leave_date.forEach((lvdt, key) => {
                        let lvdtMonth = new DateObject(lvdt.date).monthIndex;
                        if (lvdtMonth === month) {
                            if ((actDays_w_pay + usedDays_w_pay) !== days_w_pay) {
                                actDays_w_pay++;
                            } else {
                                actDays_wo_pay++;
                            }
                        } else if (lvdtMonth < month) {
                            if ((actDays_w_pay + usedDays_w_pay) !== days_w_pay) {
                                usedDays_w_pay++;
                            } else {
                                usedDays_wo_pay++;
                            }
                        }
                    })

                    if (leave.leave_type_id === 3) {
                        slwp = parseFloat(slwp + actDays_w_pay);
                    } else if (leave.leave_type_id === 1 || leave.leave_type_id === 2) {
                        vsplwp = parseFloat(vsplwp + actDays_w_pay);
                    } else if (leave.leave_type_id >= 4 && leave.leave_type_id <= 13) {
                        vsplwp = parseFloat(vsplwp + actDays_w_pay);
                    }
                    dwop = dwop + actDays_wo_pay;
                });

                //console.log(props.esta);

                let amRow = <tr key={`${ekey}-tram`} className="text-center">
                    <td rowSpan="2" style={{ width: `30px` }}>{count}</td>
                    <td rowSpan="2">{emp.name}</td>
                    {tdam}
                    <td rowSpan="2">{vsplwp}</td>
                    <td rowSpan="2">{slwp}</td>
                    <td rowSpan="2">{dwop}</td>
                    <td rowSpan="2">{lateC}</td>
                    <td rowSpan="2">{utTot}</td>
                    <td rowSpan="2">{props.esta !== "JOB ORDER" ? parseFloat(utTot / 60 / 8).toFixed(3) : ""}</td>
                </tr>

                let pmRow = <tr key={`${ekey}-trpm`} className="text-center">
                    {tdpm}
                </tr>
                empRows.push(amRow);
                empRows.push(pmRow);

            }
        })
        return empRows;
    }

    return (
        <>
            {
                loopArray.map((_, index) => {
                    return <div className="m-4 page-break" key={index} style={{ fontSize: `${fSize}px` }}>
                        <style nonce="hris-style">{getPageMargins()}</style>
                        <Row>
                            <Col xs="3"></Col>
                            <Col xs="6" style={{ fontSize: `${fSize + 7}px` }}>
                                <Row className="text-center fw-bold">
                                    <Col>ATTENDANCE REPORT</Col>
                                </Row>
                                <Row className="text-center fw-bold">
                                    <Col>{`For the Month of ${props.selectedDate.month.name} ${rangeStart}-${rangeEnd}, ${props.selectedDate.year}`}</Col>
                                </Row>
                            </Col>
                            <Col xs="3"></Col>
                        </Row>
                        <Row className="fw-bold">
                            <Col className="ps-0">{`OFFICE: ${props.user.office.offdesc}`.toLocaleUpperCase()}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="p-0">
                                <table className="emp-attend-report" style={{ width: `100%` }}>
                                    <thead className="text-center">
                                        <tr>
                                            <td rowSpan="4" colSpan="2" style={{ width: `200px` }}>NAME</td>
                                            {renderDayCol()}
                                            <td colSpan="3" style={{ width: `90px` }}>TOTAL ABSENCES</td>
                                            <td colSpan="2" style={{ width: `90px`, fontSize: `${fSize - 3}px` }}>TARDINESS / UNDERTIME</td>
                                            <td rowSpan="4" style={{ width: `45px` }}>LEAVE DEDUC TION</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ width: `60px` }}>LWP</td>
                                            <td rowSpan="3" style={{ width: `30px` }}>L w/o P</td>
                                            <td rowSpan="3" style={{ width: `30px` }}>TOTAL # OF TIMES LATE</td>
                                            <td rowSpan="3" style={{ width: `30px` }}>TOTAL MINU TES LATE</td>
                                        </tr>
                                        <tr>
                                            {renderDayCol("AM", 1)}
                                            <td rowSpan="2" style={{ width: `30px` }}>VL/SPL</td>
                                            <td rowSpan="2" style={{ width: `30px` }}>SL</td>
                                        </tr>
                                        <tr>
                                            {renderDayCol("PM", 1)}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td colSpan="2" rowSpan="2" className="ps-1">{props.esta}:</td>
                                            {renderDayCol("", 1)}
                                            <td rowSpan="2">&nbsp;</td>
                                            <td rowSpan="2">&nbsp;</td>
                                            <td rowSpan="2">&nbsp;</td>
                                            <td rowSpan="2">&nbsp;</td>
                                            <td rowSpan="2">&nbsp;</td>
                                            <td rowSpan="2">&nbsp;</td>
                                        </tr>
                                        <tr>{renderDayCol("", 1)}</tr>
                                        {renderReport(index)}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={5} style={{ fontSize: `${fSize + 1}px` }}>
                                <Form.Text className="fw-bold">
                                    LEGEND:
                                </Form.Text>
                                <Row>
                                    <Col>
                                        VL : Vacation Leave <br />
                                        SL : Sick Leave <br />
                                        SPL : Special Privilege Leave<br />
                                        *SPL : Solo Parent Leave <br />
                                        CTO : Compensatory Time Off <br />
                                        ML : Maternity Leave <br />
                                        OS : On Seminar <br />
                                        OB : Official Business <br />
                                        FL : Forced Leave
                                    </Col>
                                    <Col>
                                        A : Absent <br />
                                        WFH : Work From Home <br />
                                        OD : Off Duty <br />
                                        RD : Rest Day <br />
                                        CQL : Covid-19 Quarantine Leave<br />
                                        CQTL : Covid-19 Quarantine/Treatment Leave<br />
                                        OVDS : Orientation and Values Dev't. Sem.<br />
                                        MFW : Magna Carta for Women
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="ms-3 me-3">
                                <Row className="fst-italic fw-bold mb-5"><Col style={{ fontSize: `${fSize - 1}pt` }}>
                                    Prepared and Reviewed By:
                                </Col></Row>
                                <Row className="text-center fw-bolder border-bottom border-1 border-dark ms-1 me-1" style={{ fontSize: `${fSize}pt` }}><Col>
                                    {`${props.user.plantilla.salutation ? props.user.plantilla.salutation + " " : ""}${props.user.plantilla.FINAME} ${props.user.plantilla.MIDNAME.split(" ").map(word => word[0].toUpperCase() + ".").join("")} ${props.user.plantilla.SURNAME}`.toUpperCase()}
                                </Col></Row>
                                <Row className="text-center fw-bolder ms-1 me-1"><Col style={{ fontSize: `${fSize - 1}pt` }}>
                                    {props.user.position.description}
                                </Col></Row>
                            </Col>
                            {/* <Col className="ms-3 me-3">
                        <Row className="fst-italic fw-bold mb-5"><Col style={{ fontSize: `${fSize - 1}pt` }}>
                            Reviewed By:
                        </Col></Row>
                        <Row className="text-center fw-bolder border-bottom border-1 border-dark ms-1 me-1" style={{ fontSize: `${fSize}pt` }}><Col>
                            {`${props.user.plantilla.salutation ? props.user.plantilla.salutation + " " : ""}${props.user.plantilla.FINAME} ${props.user.plantilla.MIDNAME.split(" ").map(word => word[0].toUpperCase() + ".").join("")} ${props.user.plantilla.SURNAME}`.toUpperCase()}
                        </Col></Row>
                        <Row className="text-center fw-bolder ms-1 me-1"><Col style={{ fontSize: `${fSize - 1}pt` }}>
                            {props.user.position.description}
                        </Col></Row>
                    </Col> */}
                            <Col className="ms-3 me-3">
                                <Row className="fst-italic fw-bold mb-5"><Col style={{ fontSize: `${fSize - 1}pt` }}>
                                    Approved By:
                                </Col></Row>
                                <Row className="text-center fw-bolder border-bottom border-1 border-dark ms-1 me-1" style={{ fontSize: `${fSize}pt` }}><Col>
                                    {props.assignee ? `${props.assignee.salutation ? props.assignee.salutation + " " : ""}${props.assignee.FINAME} ${props.assignee.MIDNAME.split(" ").map(word => word[0].toUpperCase() + ".").join("")} ${props.assignee.SURNAME}`.toUpperCase() : <br />}
                                </Col></Row>
                                <Row className="text-center fw-bolder ms-1 me-1"><Col style={{ fontSize: `${fSize - 1}pt` }}>
                                    {props.assignee ? props.assignee.position_title : <br />}
                                </Col></Row>
                            </Col>
                            {/* </Row>
                    </Col> */}
                        </Row>
                    </div>
                })
            }
        </>
    );
});