import React, { useState } from 'react'
import Table from 'react-bootstrap/Table';
import '../../../Styles.css'
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import axios from '../../../services/axios';
import Modal from 'react-bootstrap/Modal';
import AttendanceModal from './components/AttendanceModal.component';

export default function AttendancePage({ listDailyTimeRecord, setDailyTimeRecord, actor, certifyLoad, userIsMobile }) {
    // console.log(listDailyTimeRecord);
    // const [selectAll, setSelectAll] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [dtrList, setDtrList] = useState([]);
    const [dataModal, setDataModal] = useState('');
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    function getMonthName(monthNumber) {
        const date = new Date(Date.UTC(2000, monthNumber - 1, 1));
        return date.toLocaleString('en-us', { month: 'long' });
    }

    const handleCheckboxChange = (dtr_id, dtrPeriod) => {
        if (selectedCheckboxes.includes(dtr_id)) {
            setSelectedCheckboxes(selectedCheckboxes.filter(id => id !== dtr_id));
        } else {
            setSelectedCheckboxes([...selectedCheckboxes, dtr_id]);
        }

        if (dtrList.includes(dtrPeriod)) {
            setDtrList(dtrList.filter(per => per !== dtrPeriod));
        } else {
            setDtrList([...dtrList, dtrPeriod]);
        }
    };

    const handleSelectAll = () => {
        const tempDTR = [];
        const allIds = listDailyTimeRecord.reduce((ids, data) => {

            if (data.period === 1) {
                ids.push(data.dtr_id);
                tempDTR.push({ unique: data.user_id, month: data.month, year: data.year, mode: data.period });

            } else if (data.period === 2) {

                ids.push(data.dtr_id);
                tempDTR.push({ unique: data.user_id, month: data.month, year: data.year, mode: data.period });

            } else if (data.period === 3) {

                ids.push(data.dtr_id);
                tempDTR.push({ unique: data.user_id, month: data.month, year: data.year, mode: data.period });
            }

            return ids;
        }, []);
        setDtrList(tempDTR);
        setSelectedCheckboxes(allIds);
    };

    const handleSelectAllCheckboxChange = () => {
        if (selectedCheckboxes.length === listDailyTimeRecord.length) {
            setDtrList([]);
            setSelectedCheckboxes([]);
        } else {
            handleSelectAll();
        }
    };


    const ShowModalHandle = (index) => {
        setShowModal(true);
        //console.log(index);
        setCurrentIndex(index);
        //setDataModal(listDailyTimeRecord[index])
    };

    const prevDTR = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    }

    const nextDTR = () => {
        if ((currentIndex + 1) < listDailyTimeRecord.length) {
            setCurrentIndex(currentIndex + 1);
        }
    }

    const handleClose = () => {
        setShowModal(false);
    }



    const BtnApproved = async (e) => {
        e.preventDefault();

        if (!selectedCheckboxes.length) {

            Swal.fire({
                title: "Ooops.!",
                text: "Please select at least one checkbox",
                icon: "info"
            });
        } else {
            try {
                Swal.fire({
                    icon: 'info',
                    text: 'Processing approval...',
                    showConfirmButton: false
                });
                await axios.post(`/api/office/approved_daily_time_record`, {
                    selectedChkBox: selectedCheckboxes,
                    dtrList: dtrList,
                }).then((response) => {
                    //console.log(response);
                    if (response.status === 200) {
                        Swal.fire({
                            icon: "success",
                            title: response.data.message,
                        }).then((result) => {
                            const updatedList = listDailyTimeRecord.filter(app => !selectedCheckboxes.includes(app.dtr_id));
                            setSelectedCheckboxes([]);
                            setDtrList([]);
                            setDailyTimeRecord(updatedList);
                        });
                    } else {
                        Swal.update({
                            icon: "error",
                            text: "Something went wrong",
                            showConfirmButton: true
                        });
                    }
                });

            } catch (error) {
                Swal.update({
                    icon: "error",
                    text: "Something went wrong",
                    showConfirmButton: true
                });
                console.error("Error:", error);
            }

        }
    }

    const approveSingle = async (e) => {
        try {
            Swal.fire({
                icon: 'info',
                text: 'Processing approval...',
                showConfirmButton: false
            });
            let dtr = listDailyTimeRecord[currentIndex];
            await axios.post(`/api/office/approved_daily_time_record`, {
                selectedChkBox: [dtr.dtr_id],
                dtrList: [{
                    mode : dtr.period,
                    month : dtr.month,
                    unique : dtr.user_id,
                    year : dtr.year
                }],
            }).then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: response.data.message,
                    }).then((result) => {
                        const updatedList = listDailyTimeRecord.filter(app => app.dtr_id !== dtr.dtr_id);
                        setSelectedCheckboxes([]);
                        setDtrList([]);
                        setDailyTimeRecord(updatedList);
                        if((currentIndex+1) > updatedList.length){
                            setCurrentIndex(currentIndex-1);
                        }
                    });
                } else {
                    Swal.update({
                        icon: "error",
                        text: "Something went wrong",
                        showConfirmButton: true
                    });
                }
            });
        } catch (error) {
            Swal.update({
                icon: "error",
                text: "Something went wrong",
                showConfirmButton: true
            });
            console.error("Error:", error);
        }
    }

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        overflowY: 'auto',
        ...(listDailyTimeRecord.length) > 6 ? { height: '240px' } : {}
    };

    return (
        <div>
            <div style={tableStyle}>
                <Table className="approvalTbl" responsive striped bordered hover style={{ margin: '0px' }}>
                    {!userIsMobile ? <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff', fontSize: '10pt' }}>
                        <tr>
                            <th style={{ padding: '3px' }}>
                                <div className="form-check d-flex flex-column align-items-center justify-content-center">
                                    <input className="form-check-input" type="checkbox" checked={selectedCheckboxes.length === listDailyTimeRecord.length} onChange={handleSelectAllCheckboxChange} />
                                </div>
                            </th>
                            <th style={{ padding: '3px', verticalAlign: 'middle' }}>Employee Name</th>
                            <th style={{ padding: '3px', verticalAlign: 'middle' }}>Period</th>
                            <th style={{ padding: '3px', verticalAlign: 'middle' }}>Employment Status</th>
                            <th style={{ padding: '3px', verticalAlign: 'middle', textAlign: 'center' }}>Action</th>
                        </tr>
                    </thead> : null}
                    <tbody className="table-group-divider">
                        {userIsMobile ? <tr>
                            <td>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={selectedCheckboxes.length === listDailyTimeRecord.length} onChange={handleSelectAllCheckboxChange} /> Select All
                                </div>
                            </td>
                        </tr> : null}
                        {
                            listDailyTimeRecord.map((data, index) => {

                                const {
                                    dtr_id,
                                    user_id,
                                    SURNAME,
                                    FINAME,
                                    MIDNAME,
                                    job_status,
                                    month,
                                    year,
                                    period,
                                } = data;

                                const fullname = SURNAME + ', ' + FINAME + ' ' + MIDNAME;

                                let dtr_month;
                                let dtrPeriod;

                                if (job_status === "JO" || job_status === "CA" || job_status === "CON") {

                                    if (period === 2) {
                                        dtr_month = `${getMonthName(month)} ${year} - A`;
                                        dtrPeriod = { unique: user_id, month: month, year: year, mode: period };
                                    }
                                    else if (period === 3) {
                                        dtr_month = `${getMonthName(month)} ${year} - B`;
                                        dtrPeriod = { unique: user_id, month: month, year: year, mode: period };
                                    }

                                } else if (job_status === "Permanent" || job_status === "Co-Terminous") {
                                    if (period === 1) {
                                        dtr_month = `${getMonthName(month)} ${year}`;
                                        dtrPeriod = { unique: user_id, month: month, year: year, mode: period };
                                    }
                                }


                                let job_statuss = null;

                                switch (job_status.toLowerCase()) {
                                    case 'permanent':
                                        job_statuss = "Permanent";
                                        break;
                                    case 'co-terminous':
                                        job_statuss = "Co-Terminous";
                                        break;
                                    case 'jo':
                                        job_statuss = "Job Order";
                                        break;
                                    case 'ca':
                                        job_statuss = "Casual";
                                        break;
                                    case 'con':
                                        job_statuss = "Consultant";
                                        break;
                                    default:
                                        // Handle default case, if needed
                                        break;
                                }

                                //console.log(listDailyTimeRecord);
                                return !userIsMobile ?
                                    (<tr key={index} style={{ fontSize: '10pt' }}>
                                        <td style={{ width: '3%', padding: '0px', verticalAlign: 'middle' }}>
                                            <div className="form-check d-flex flex-column align-items-center">
                                                <input className="form-check-input" type="checkbox" checked={selectedCheckboxes.includes(dtr_id)} onChange={() => handleCheckboxChange(dtr_id, dtrPeriod)} />
                                            </div>
                                        </td>
                                        <td style={{ padding: '0px', verticalAlign: 'middle' }}>{fullname}</td>
                                        <td style={{ padding: '0px', verticalAlign: 'middle', width: '15%' }}>
                                            {dtr_month}
                                        </td>
                                        <td style={{ padding: '0px', verticalAlign: 'middle', width: '15%' }}>{job_statuss}</td>
                                        <td className="text-center" style={{ padding: '1px', width: '5%' }}>
                                            <Button size="sm" type="button" variant="primary" onClick={() => ShowModalHandle(index)}>View</Button>
                                        </td>
                                    </tr>) :
                                    (<tr key={index}>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={selectedCheckboxes.includes(dtr_id)} onChange={() => handleCheckboxChange(dtr_id, dtrPeriod)} />
                                                <b>Name:</b> {fullname}
                                                <br />
                                                <b>Period:</b> {dtr_month}
                                                <br />
                                                <b>Employment Status:</b> {job_statuss}
                                                <br />
                                                <Button size="sm" type="button" variant="primary" onClick={() => ShowModalHandle(index)}>View</Button>
                                            </div>
                                        </td>
                                    </tr>)
                            })
                        }


                    </tbody>
                </Table>
            </div>
            <div className="d-flex flex-row-reverse p-1">
                {/* <Button size="sm" variant="success" disabled={certifyLoad} onClick={!certifyLoad ? BtnApproved : null}>
                Certify
            </Button> */}
                <Button size="sm" variant="success" onClick={BtnApproved}>
                    Certify
                </Button>
            </div>
            {/* Modal for DTR */}
            <Modal show={showModal} onHide={handleClose} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <span className="fs-4 fw-semibold">
                        Daily Time Record (DTR)
                        &nbsp;
                        &nbsp;
                        <Button size="sm" disabled={currentIndex === 0} onClick={prevDTR}>{"<"}</Button>
                        &nbsp;
                        &nbsp;
                        <Button size="sm" disabled={currentIndex+1 === listDailyTimeRecord.length} onClick={nextDTR}>{">"}</Button>
                        &nbsp;
                        &nbsp;
                        <Button variant="success" disabled={isLoading} onClick={approveSingle}>Certify</Button>
                    </span>
                </Modal.Header>
                <Modal.Body style={modalBody}>
                    {listDailyTimeRecord[currentIndex] ?
                        <AttendanceModal
                            setIsLoading={setIsLoading}
                            user_id={listDailyTimeRecord[currentIndex].user_id}
                            dataModal={listDailyTimeRecord[currentIndex]}
                        />
                        : null}
                </Modal.Body>
            </Modal>



        </div>



    )
}

const modalBody = {
    maxHeight: 'calc(100vh - 150px)',
    overflowY: 'auto',
};

