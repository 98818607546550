import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../../../Styles.css';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';
import { DateObject } from 'react-multi-date-picker';

export default function AttendanceModal(props) {
    const { dataModal } = props;
    const {
        user_id,
        SURNAME,
        FINAME,
        MIDNAME,
        job_status,
        month,
        year,
        period
      } = dataModal;

    let dtr_month;
    let dtrPeriod;

      if (job_status === "JO" || job_status === "CA" || job_status === "CON") {
                
        if (period === 2) {
          dtr_month = `${getMonthName(month)} ${year} - A`;
          dtrPeriod = {unique:user_id, month:month, year:year, mode:period};
        }
        else if (period === 3) {
          dtr_month = `${getMonthName(month)} ${year} - B`;
          dtrPeriod = {unique:user_id, month:month, year:year, mode:period};
        }

      } else if (job_status === "Permanent" || job_status === "Co-Terminous") {
        if (period === 1) {
          dtr_month = `${getMonthName(month)} ${year}`;
          dtrPeriod = {unique:user_id, month:month, year:year, mode:period};
        }
      }

    const fullname = SURNAME + ', ' + FINAME + ' ' + MIDNAME;

    const [employeeSchedule, setEmployeeSchedule] = useState([]);

    function getMonthName(monthNumber) {
        const date = new Date(Date.UTC(2000, monthNumber - 1, 1));
        return date.toLocaleString('en-us', { month: 'long' });
    }

    const fetchEmployeeSchedule = async (user_id) => {

        // Destructure selectedData to avoid repeated access
        const { month, year } = dataModal;

        // Check if employee_id is defined
        if (user_id !== undefined) {
            Swal.fire({
                icon: 'info',
                text: 'Loading Schedule...',
                showConfirmButton: false
            });
            // Use axios's response directly, no need to destructure

            await axios.get(`/api/employeeschedule/${user_id}`, { params: { month: month, year: year } }).then(({ data }) => {
                if (data.result){
                    props.setIsLoading(false);
                    setEmployeeSchedule(data.sched);
                    Swal.close();
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                }
            }).catch((error) => {
                //console.log(response);
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            });

        }

    };

    useEffect(() => {
        props.setIsLoading(true);
        fetchEmployeeSchedule(props.user_id);
    }, [dataModal, props.user_id]);


    return (
        <>
            <Col className="m-1 ps-0 pe-2 pt-0 pb-0" sm="12" style={{ height: "100%" }}>
                {/* <div className="card overflow-auto" style={{height : "calc(100%)"}}>
                        <div className="card-body" style={{minWidth : "1100px"}}> */}
                <Row className="">
                    <Col className="">
                        <Row className="border border-2 border-bottom-2 border-dark text-center">
                            <Col className="border-end border-2 border-dark align-items-center" xs="2">
                                <Row>
                                    <Col>
                                        <Form.Label className="m-auto">
                                            Employee Schedule
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex">
                                        <Form.Label className="m-auto">
                                            <i>{fullname}</i>
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {dtr_month}
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="border-end border-2 border-dark" xs="4">
                                <Row className="border-bottom border-1 border-dark" >
                                    <Col>
                                        <Form.Label className="m-auto">Attendance</Form.Label>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-1 border-dark" >
                                    <Col className="border-end border-1 border-dark">
                                        <Form.Label className="m-auto">AM</Form.Label>
                                    </Col>
                                    <Col className="border-end border-1 border-dark">
                                        <Form.Label className="m-auto">PM</Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Label className="m-auto">OT</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="border-end border-1 border-dark">
                                        <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                    </Col>
                                    <Col className="border-end border-1 border-dark bg-light">
                                        <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                    </Col>
                                    <Col className="border-end border-1 border-dark">
                                        <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                    </Col>
                                    <Col className="border-end border-1 border-dark bg-light">
                                        <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                    </Col>
                                    <Col className="border-end border-1 border-dark">
                                        <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                    </Col>
                                    <Col className="bg-light">
                                        <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="border-end border-2 border-dark d-flex align-items-center p-0" xs="1">
                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>Undertime</Form.Label>
                            </Col>

                            <Col className="border-end border-2 border-dark d-flex align-items-center p-0" xs="1">
                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>Overtime</Form.Label>
                            </Col>

                            <Col className="border-end border-2 border-dark d-flex align-items-center p-0" xs="4">
                                <Form.Label className="m-auto">Remarks</Form.Label>
                            </Col>

                            {/* <Col className="d-flex align-items-center" xs="2">
                                            <Form.Label className="m-auto">Remarks</Form.Label>
                                        </Col> */}

                        </Row>

                        {/* employeeSchedules */}
                        <Row className="border-end border-start border-2 border-dark">
                            <Col>
                                {
                                    /* {renderScheds()} */
                                    employeeSchedule.map((sched, i) => {
                                        const date = new DateObject(sched.date);
                                        
                                        let legends = ["VL", "FL", "SL", "OB", "SPL", "*SPL", "CTO", "HL", "WS", "A", "ML", "PL", "*SL", "VAWC", "SLBW", "RP", "CL", "AL", "CTO", "CQT", "CQTL"];

                                        const checkDT = (dt, ms = false, scDt = sched.date) => {
                                            if (dt === "" || dt === null) {
                                                return "";
                                            }
                                            if (legends.includes(dt)) {
                                                return dt;
                                            }
                                            let res = ms ? new DateObject(parseInt(dt)).format("hh:mm") : new DateObject(`${scDt} ${dt}`).format("hh:mm");
                                            //console.log(dt, ms, scDt, res);
                                            return res;
                                        }

                                        let schedAmIn = checkDT(sched.sched_am_in);
                                        let schedAmOut = checkDT(sched.sched_am_out);
                                        let schedPmIn = checkDT(sched.sched_pm_in);
                                        let schedPmOut = checkDT(sched.sched_pm_out);
                                        let attAmIn = checkDT(sched.attend_am_in, true);
                                        let attAmOut = checkDT(sched.attend_am_out, true);
                                        let attPmIn = checkDT(sched.attend_pm_in, true);
                                        let attPmOut = checkDT(sched.attend_pm_out, true);
                                        let otCutOff = checkDT(sched.ot_cutoff);
                                        let otIn = checkDT(sched.ot_in, true);
                                        let otOut = checkDT(sched.ot_out, true);

                                        let utTot = 0;
                                        if (sched.undertime) {
                                            let ut = sched.undertime.split(",").map(Number);
                                            utTot = ut[0] + ut[1] + ut[2] + ut[3];
                                        }

                                        return (
                                            <Row key={i} className="border-bottom border-1 border-dark text-center">
                                                <Col className="border-end border-2 border-dark text-end editSchedButton" xs="2">
                                                    <Form.Label className="p-0 m-0 text-dark editSchedButton">{`${date.weekDay.shortName}, ${date.month.name} ${date.day}`}</Form.Label>
                                                </Col>
                                                <Col className="border-end border-2 border-dark" xs="4">
                                                    <Row>
                                                        <Col className={"border-end border-1 p-0 border-dark" + (sched.am_in_entry === 0 ? "" : sched.am_in_entry === 1 ? " text-primary" : sched.am_in_entry === 2 ? " text-danger" : sched.am_in_entry === 4 ? "" : " text-success-lighter")}>
                                                            &nbsp;{attAmIn}
                                                        </Col>
                                                        <Col className={"border-end border-1 p-0 border-dark bg-light" + (sched.am_out_entry === 0 ? "" : sched.am_out_entry === 1 ? " text-primary" : sched.am_out_entry === 2 ? " text-danger" : sched.am_out_entry === 4 ? "" : " text-success-lighter")}>
                                                            &nbsp;{attAmOut}
                                                        </Col>
                                                        <Col className={"border-end border-1 p-0 border-dark" + (sched.pm_in_entry === 0 ? "" : sched.pm_in_entry === 1 ? " text-primary" : sched.pm_in_entry === 2 ? " text-danger" : sched.pm_in_entry === 4 ? "" : " text-success-lighter")}>
                                                            &nbsp;{attPmIn}
                                                        </Col>
                                                        <Col className={"border-end border-1 p-0 border-dark p-0 bg-light" + (sched.pm_out_entry === 0 ? "" : sched.pm_out_entry === 1 ? " text-primary" : sched.pm_out_entry === 2 ? " text-danger" : sched.pm_out_entry === 4 ? "" : " text-success-lighter")}>
                                                            &nbsp;{attPmOut}
                                                        </Col>
                                                        <Col className={"border-end border-1 p-0 border-dark" + (sched.ot_in_entry === 0 ? "" : sched.ot_in_entry === 1 ? " text-primary" : sched.ot_in_entry === 2 ? " text-danger" : " text-success-lighter")}>
                                                            &nbsp;{otIn}
                                                        </Col>
                                                        <Col className={"p-0 bg-light" + (sched.ot_out_entry === 0 ? "" : sched.ot_out_entry === 1 ? " text-primary" : sched.ot_out_entry === 2 ? " text-danger" : " text-success-lighter")}>
                                                            &nbsp;{otOut}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="p-0 border-end border-2 border-dark" xs="1">
                                                    &nbsp;{utTot}
                                                </Col>
                                                <Col className="border-end border-2 border-dark" xs="1">
                                                    &nbsp;{sched.overtime}
                                                </Col>
                                                <Col className={`border-end border-2 border-dark ${sched.sched_approved === 1 ? "" : "text-danger"}`} xs="4">
                                                    <Row>
                                                        <Col className="border-end border-1 border-dark p-0">
                                                            &nbsp;{sched.remarks}
                                                        </Col>
                                                        {/* <Col className="border-end border-1 border-dark p-0">
                                                            &nbsp;{schedAmIn}
                                                        </Col>
                                                        <Col className="border-end border-1 border-dark p-0 bg-light">
                                                            &nbsp;{schedAmOut}
                                                        </Col>
                                                        <Col className="border-end border-1 border-dark p-0">
                                                            &nbsp;{schedPmIn}
                                                        </Col>
                                                        <Col className="border-end border-1 border-dark p-0 bg-light">
                                                            &nbsp;{schedPmOut}
                                                        </Col>
                                                        <Col className="border-end border-1 border-dark p-0">
                                                            &nbsp;{sched.ot_indicator}
                                                        </Col>
                                                        <Col className="p-0" xs="2">
                                                            &nbsp;{otCutOff}
                                                        </Col> */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </Col>
                        </Row>

                        {

                        }
                    </Col>
                </Row>
                {/* </div>
                    </div> */}
            </Col>


        </>
    )
}

