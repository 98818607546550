import React, { useState } from 'react'
import Table from 'react-bootstrap/Table';
import '../../../Styles.css'
import Button from 'react-bootstrap/Button';
//import { format } from 'date-fns';
import axios from '../../../services/axios';
import Swal from 'sweetalert2';

import TravelOrderModal from './components/TravelOrderModal.component';
import { DateObject } from "react-multi-date-picker";

export default function TravelOrderPage({ listTravelOrder, setListTravelOrder, actor, userIsMobile }) {
  
  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState('');
  
  const OpenModalDetails = (data) => {
    setShowModal(true);
    setDataModal(data);
  };


    const [selectAll, setSelectAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);


const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedCheckboxes(selectAll ? [] : listTravelOrder.map(data => data.travel_id));
};


  const handleCheckboxChange = (travel_id) => {
        setSelectedCheckboxes(prevState => {
            if (prevState.includes(travel_id)) {
                return prevState.filter(item => item !== travel_id);
            } else {
                return [...prevState, travel_id];
            }
        });
  };
  
const BtnApprovedTO = async (e,decision) => {
  e.preventDefault();

  if (!selectedCheckboxes.length) {
    Swal.fire({
      title: "Ooops.!",
      text: "Please select at least one checkbox",
      icon: "info"
    });
  } else {
    
    Swal.fire({
        icon:'info',
        text:'Processing approval...',
        showConfirmButton:false
    });

    try {
      const response = await axios.post(`/api/office/approve_travelorder`, {
        selectchkbox: selectedCheckboxes,
        decision: decision,
        actor: actor
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: response.data.message,
        }).then((result) => {
          const updatedList = listTravelOrder.filter(app => !selectedCheckboxes.includes(app.travel_id));
          setListTravelOrder(updatedList);
          setSelectedCheckboxes([]);
        });
      } else {
        Swal.update({
            icon:"error",
            text:"Something went wrong",
            showConfirmButton:true
        });
        // Handle other status codes or conditions if necessary
      }
    } catch (error) {
        Swal.update({
            icon:"error",
            text:"Something went wrong",
            showConfirmButton:true
        });
      console.error("Error:", error);
      // Handle errors here
    }
  }
}
const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
  overflowY: 'auto',
  ...(listTravelOrder.length)>6 ? {height: '240px'} : {}
}; 

  return (
    <div>
      <div style={tableStyle}>
      <Table className="approvalTbl" responsive striped bordered hover style={{ margin: '0px' }}>
      { !userIsMobile ? <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff', fontSize: '10pt' }}>
          <tr>
              <th  style={{ padding: '3px' }}>
                    <div className="form-check d-flex flex-column align-items-center justify-content-center">
                            <input className="form-check-input" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                    </div> 
                </th>
              {actor==='pgo' ? <th style={{ padding: '3px', verticalAlign: 'middle'}}>Department</th>: <></>}
              <th style={{ padding: '3px', verticalAlign: 'middle'}}>Date</th>
              <th style={{ padding: '3px', verticalAlign: 'middle'}}>Station</th>
              <th style={{ padding: '3px', verticalAlign: 'middle'}}>Event or Purpose</th>
              <th style={{ padding: '3px', verticalAlign: 'middle'}}>Travel Dates</th>
              <th style={{ padding: '3px', verticalAlign: 'middle', textAlign: 'center'}}>Action</th>
          </tr>
        </thead> : null }
        <tbody className="table-group-divider">
            {userIsMobile ? <tr>
                <td>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" checked={selectAll} onChange={handleSelectAll} /> Select All
                    </div>
                </td>
            </tr> : null}
          {
            listTravelOrder.map((data) => {
              
              const {
                  created_at,
                  travel_id, 
                  station,
                  start_date,
                  end_date,
                  event,
                  short
              } = data;
              
              const dateapplied = new DateObject(created_at).format("YYYY/MM/DD");
              const startdate = new DateObject(start_date).format("YYYY/MM/DD");
              const enddate = new DateObject(end_date).format("YYYY/MM/DD");

              return !userIsMobile ? (<tr key={travel_id}  style={{ fontSize: '10pt'}}>
                    <td  style={{ width: '3%', padding: '0px', verticalAlign: 'middle'}}>
                            <div className="form-check d-flex flex-column align-items-center">
                            <input className="form-check-input" type="checkbox" checked={selectedCheckboxes.includes(travel_id)} onChange={() => handleCheckboxChange(travel_id)} />
                            </div> 
                    </td>
                    {actor==='pgo' ? 
                    <td  style={{ padding: '0px', verticalAlign: 'middle', width: '10%'}}>
                    {short}
                    </td>
                    : <></>}
                    <td  style={{ padding: '0px', verticalAlign: 'middle', width: '8%'}}>
                        {dateapplied}
                    </td>
                    <td style={{ padding: '0px', verticalAlign: 'middle', width: '20%'}}>
                        {station}
                    </td>
                    <td style={{ padding: '0px', verticalAlign: 'middle'}}>
                        {event}
                    </td>
                    <td style={{ padding: '0px', verticalAlign: 'middle', width: '15%'}}>
                        {
                            startdate === enddate ? enddate  : startdate +" - "+ enddate           
                        }
                    </td>
                    <td className="text-center" style={{ padding: '1px', width: '5%'}}>
                            <Button size="sm" type="button" variant="primary" onClick={ () => OpenModalDetails(data) }>View</Button>
                    </td>
                    </tr>) :
                    (<tr key={travel_id}>
                        <td>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" checked={selectedCheckboxes.includes(travel_id)} onChange={() => handleCheckboxChange(travel_id)} />
                                <b>Event:</b> {event}
                                <br />
                                {actor === "pgo" ? <><b>Office: </b>{short}
                                <br /></> : null}
                                <b>Date Applied:</b> {dateapplied ?? ""}
                                <br />
                                <b>Station:</b> {station}
                                <br />
                                <b>Travel Dates:</b> {startdate === enddate ? enddate  : startdate +" - "+ enddate}
                                <br />
                                <Button size="sm" type="button" variant="primary" onClick={ () => OpenModalDetails(data) }>View</Button>
                            </div>
                        </td>
                    </tr>)
            })
          }
        </tbody> 
        </Table> 
      </div>
      <div className="d-flex flex-row-reverse p-1">
      <Button size="sm" variant="danger" onClick={(e)=>BtnApprovedTO(e,0)}>
                Decline
            </Button>
            &nbsp;
            <Button size="sm" variant="success" onClick={(e)=>BtnApprovedTO(e,1)}>
                Approve
            </Button>
        </div> 
      <TravelOrderModal showModal={showModal} setShowModal={setShowModal} dataModal={dataModal} />
    </div>


  )
}