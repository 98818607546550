import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom'
import DatePicker, { DateObject } from "react-multi-date-picker";
import SearchIcon from '@mui/icons-material/Search';


export default function QueryFilter(props) {
    const navigate = useNavigate();
    let curData = {};

    Object.entries(props.inputs).map(([key, val], i) => {
        curData[key] = val.input === "date" ? val.defaultValue || new DateObject().format('YYYY-MM-DD') : val.defaultValue !== undefined ? val.defaultValue : '';
    });

    const [inputData, setInputData] = useState(curData);

    useEffect(()=>{
        props.fetch(inputData, props.perPage);
    }, [props.page])

    return (
        <Row className="">
            {Object.entries(props.inputs).map(([key, val], ii) => {
                let formInput = '';
                switch(val.input){
                    case 'select':
                        formInput = <Col xs={6} md="auto" key={ii}><Form.Select className="query-input mt-1 mb-1" name={key} value={inputData[key]} onChange={(event) => {
                            let val = event.target.value;
                            if(val !== '' && val !== 'null'){
                                val = isNaN(val) ? val : parseInt(val);
                            }
                            let inData = {...inputData};
                            inData[key] = val;
                            setInputData(inData);
                        }} >
                            {val.blankOption ? <option value={val.blankOption.value}>{val.blankOption.label}</option> : ''}
                            {Object.entries(val.selectOptions).map(([key, opt], i) => {
                                if(val.optionMap){
                                    return (<option key={i} value={opt[val.optionMap.value]}>{opt[val.optionMap.label]}</option>)
                                }else{
                                    return (<option key={i} value={opt.value}>{opt.label}</option>)
                                }
                            })}
                        </Form.Select></Col>
                        break;
                    case 'date':
                        formInput = <Col xs={6} md="auto" key={ii}><DatePicker
                            value={inputData[key]}
                            onChange={(date) => {
                                let inData = {...inputData};
                                inData[key] = date.format('YYYY-MM-DD');
                                setInputData(inData);
                            }}
                            inputClass="form-control query-input mt-1 mb-1"
                        /></Col>
                        break;
                    case 'input':
                        formInput = <Col xs={6} md="auto" key={ii}><Form.Control className="query-input mt-1 mb-1" as="input" placeholder="Enter TO NO." value={inputData[key]} onChange={(event)=>{
                            let val = event.target.value;
                            let inData = {...inputData};
                            inData[key] = val;
                            setInputData(inData);
                        }}/></Col>
                    default:
                        break;
                }
                
                return formInput;
            })}
            <Col xs={6} md="auto">
                <Button className="mt-1 mb-1" onClick={(e) => {
                    if(props.page === 1){
                        props.fetch(inputData);
                    }else{
                        props.setPage(1);
                    }
                }}>
                    <SearchIcon />
                </Button>&nbsp;
                        <Button size="sm" variant="primary" onClick={props.openledger} style={{ height: '80%', width: '1.5in' }}>
                                Employee Ledgers
                        </Button>
                
            </Col>
        </Row>  
    )
}