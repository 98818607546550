import React from 'react';
import PageIndex from './PageIndex.component.js';

export default function Page(props) {
    return (
        <div className="container-fluid page-container">
            <PageIndex user={props.user}
                showEmployeeDetails={true}
            //allowPrint={true}
            />
        </div>
    )
}