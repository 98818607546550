import LeaveApplicationCreate from './CreateModal.component';
import LeaveApplicationModal from '../../Global/LeaveApplicationModal.component';
import QueryFilter from '../../Global/QueryFilter.component';
import { LeaveApplicationLayout } from '../../Global/PrintComponents/LeaveApplicationLayout.component';
import LeaveLedger from '../../HRIS/Reports/LeaveLedger';

import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import axios from '../../../services/axios';
import { DateObject } from "react-multi-date-picker";
import ReactToPrint from 'react-to-print';
import { useLocation } from 'react-router-dom'

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PrintIcon from '@mui/icons-material/Print';
import Swal from "sweetalert2";

export default function PageIndex(props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const leaveID = queryParams.get('leave');
    let userIsMobile = window.innerWidth <= 768;

    const [leaveIDParam, setLeaveIDParam] = useState(leaveID);
    const [showCreate, setShowCreate] = useState(false);
    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [showPrint, setShowPrint] = useState(false);
    const [showLeaveID, setShowLeaveID] = useState(null);
    const [showLedger, setShowLedger] = useState(false);

    const closeLedger = () => setShowLedger(false);
    const openCreate = () => setShowCreate(true);
    const closeCreate = () => setShowCreate(false);
    const closeFetchCreate = () => {
        setShowCreate(false);
        fetchLeaves(filter);
    }

    const openledger = async () => {
        await getledger();
        setShowLedger(true);
    }

    const getledger = async () => {
        const response = await axios.get(`/api/userLeaveLedger`)
        if (response.data.result === 0) {
            Swal.fire({
                text: "Data not found",
                icon: "error",
            });
        }
        else {
            if (response.data.result.ledger.length === 0) {
                Swal.fire({
                    text: "There is no leave ledger record found.",
                    icon: "error",
                });
            }
            else {
                setLedger(response.data.result);

            }
        }
    }

    const openLeaveModal = () => setShowLeaveModal(true);
    const closeLeaveModal = () => setShowLeaveModal(false);
    const closeFetchLeaveModal = () => {
        setShowLeaveModal(false);

        fetchLeaves(filter);
    }

    const openPrint = (lvID) => {
        setShowLeaveID(lvID)
        setShowPrint(true);
    };

    const closePrint = () => setShowPrint(false);

    const [leaves, setLeaves] = useState([]);

    const [leavesArranged, setLeavesArranged] = useState({});
    const [ledger, setLedger] = useState(null);

    useEffect(() => {
        let lvgr = {};
        leaves.forEach(lv => {
            if (!(lv.office.offdesc in lvgr)) {
                lvgr[lv.office.offdesc] = {};
            }
            let empName = lv.plantilla.FINAME + " " + lv.plantilla.MIDNAME + " " + lv.plantilla.SURNAME;
            if (!(empName in lvgr[lv.office.offdesc])) {
                lvgr[lv.office.offdesc][empName] = [];
            }
            lvgr[lv.office.offdesc][empName].push(lv.id);
        });
        setLeavesArranged(lvgr);
    }, [leaves])

    const [filter, setFilter] = useState({});
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);

    const fetchLeaves = async (filter) => {
        if (leaveIDParam) {
            await axios.post(`/api/leaveapplication/query/${leaveIDParam}`).then(({ data }) => {
                setLeaveIDParam(null);
                setLeaves(prev => [...prev, data.leave])
                setPages(0);
            })
        } else {
            setFilter(filter);
            setLeavesArranged({});
            setShowLeaveID(null);
            await axios.post(`/api/leaveapplication/index`, { ...filter, page, perPage }).then(({ data }) => {
                setLeaves(data.index);
                if (data.lastPage !== pages) {
                    setPages(data.lastPage);
                }
            })
        }
    }

    const componentRef = useRef();

    return (
        <div>
            <Row className="page-index-controls cus-color-2">
                <Col xs={12} md={"auto"}>
                    <Row className="">
                        <Col className="mt-1 mb-1" xs={6} md={"auto"}>
                            <Button variant="primary" onClick={openCreate}>
                                Apply for Leave
                            </Button>
                        </Col>
                        <Col className="mt-1 mb-1" xs={6} md={true}>
                            <Button variant="primary" onClick={openledger}>
                                Leave Ledger
                            </Button>
                        </Col>
                        <Col xs={6} md={true} className="mt-1 mb-1 text-light">
                            <div className="d-flex flex-row m-0 p-0" style={{ width: '2in', fontSize: '10pt', lineHeight: '13px' }}>
                                <div className="d-flex flex-column" style={{ width: '35px' }}>
                                    VL<br />SL<br />CTO
                                </div>
                                <div className="d-flex flex-column">
                                    : {parseFloat(props.user.leavecredits.vl).toFixed(3)} Day(s)<br />
                                    : {parseFloat(props.user.leavecredits.sl).toFixed(3)} Day(s)<br />
                                    : {parseFloat(props.user.cto).toFixed(3)} Hour(s)
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={"auto"}>
                    <QueryFilter
                        fetch={fetchLeaves}
                        perPage={perPage}
                        setPages={setPages}
                        page={page}
                        setPage={setPage}
                        inputs={{
                            leaveType: {
                                input: 'select',
                                blankOption: { label: 'Leave Type', value: '' },
                                selectOptions: [
                                    { label: "Vacation Leave", value: 1 },
                                    { label: "Mandatory/Forced Leave", value: 2 },
                                    { label: "Sick Leave", value: 3 },
                                    { label: "Maternity Leave", value: 4 },
                                    { label: "Paternity Leave", value: 5 },
                                    { label: "Special Privilege Leave", value: 6 },
                                    { label: "Solo Parent Leave", value: 7 },
                                    { label: "Study Leave", value: 8 },
                                    { label: "10-Day VAWC Leave", value: 9 },
                                    { label: "Special Leave Benefits for Women", value: 10 },
                                    { label: "Rehabilitation Privilege", value: 11 },
                                    { label: "Special Emergency (Calamity) Leave", value: 12 },
                                    { label: "Adoption Leave", value: 13 },
                                    { label: "Others - CTO", value: 14 },
                                    { label: "Others - Covid-19 Quarantine/Treatment Leave", value: 15 },
                                    { label: "Others - Covid-19 Quarantine Leave", value: 16 },
                                    { label: "Others - Monetization of Leave Credits", value: 17 },
                                    { label: "Others - Terminal Leave", value: 18 }
                                ],
                            },
                            leaveStatus: {
                                input: 'select',
                                selectOptions: [
                                    { label: 'Status', value: '' },
                                    { label: 'Pending', value: 0 },
                                    { label: 'Disapproved', value: 1 },
                                    { label: 'Approved', value: 2 },
                                    { label: 'Cancelled', value: 3 }
                                ]
                            },
                            dateFrom: {
                                input: 'date',
                                defaultValue: new DateObject().format("YYYY-MM") + '-01'
                            },
                            dateTo: {
                                input: 'date'
                            },
                        }}
                    />
                </Col>
            </Row>

            {pages > 1 ?
                <Row className="m-2">
                    <Col className="text-center text-light">
                        {page > 1 ? <Button size='sm' onClick={(e) => {
                            setPage(page => page - 1);
                        }}>{'<'}</Button> : null}
                        <Form.Label className="ms-2 me-2">{`Pages ${page} of ${pages}`}</Form.Label>
                        {page === pages ? "" : <Button size='sm' onClick={(e) => {
                            setPage(page => page + 1);
                        }}>{'>'}</Button>}
                    </Col>
                </Row>
                : ''}

            <Row className="mt-2 mb-2">
                {Object.entries(leavesArranged).map(([key, val]) => {
                    return (
                        <Col className="" key={key} sm={12}>
                            <div className="card">
                                <div className="card-header fw-bold">
                                    {key}
                                </div>
                                <div className="card-body pt-0 pb-0">
                                    {!userIsMobile ? <Row className="p-1 border-bottom border-1 fw-bold text-center">
                                        {/* <Col xs={12} md={true}>Employee</Col> */}
                                        <Col xs={12} md={true}>Type</Col>
                                        <Col xs={12} md={true}>Date Submitted</Col>
                                        <Col xs={12} md={true}>Status</Col>
                                        <Col xs={12} md={true}></Col>
                                    </Row> : null}
                                    {
                                        Object.entries(val).map(([empKey, empVal]) => {
                                            return (
                                                <Row className="" key={empKey}>
                                                    <Col className="">
                                                        {/* <Row className="border-bottom border-1 p-1">
                                                        <Col className="fw-bold">
                                                            {empKey}
                                                        </Col>
                                                    </Row> */}
                                                        {
                                                            empVal.map((leaveId) => {
                                                                let leave = leaves.find(lv => lv.id === leaveId);
                                                                const aoApp = leave.ao_approved;
                                                                const offApp = leave.office_approved;
                                                                return (
                                                                    <Row className="border-bottom border-1 p-1 text-center" key={leave.id}>
                                                                        {/* <Col className=""  xs={12} md={true}>
                                                                        {empKey}
                                                                    </Col> */}
                                                                        <Col className="" xs={12} md={true}>
                                                                            {userIsMobile ? <span className="fw-bold">Type: </span> : null}{leave.leave_type.type}
                                                                        </Col>
                                                                        <Col className="" xs={12} md={true}>
                                                                            {userIsMobile ? <span className="fw-bold">Date Submitted: </span> : null}{new DateObject(leave.created_at).format("MM/DD/YYYY")}
                                                                        </Col>
                                                                        <Col className="" xs={12} md={true}>
                                                                            <div className="progress mt-1" style={{ height: "20px" }}>
                                                                                {leave.status ? <>
                                                                                    <div className={`progress-bar ${aoApp !== 1 ? aoApp === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>AO</div>
                                                                                    <div className={`progress-bar ${offApp !== 1 ? offApp === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>Chief</div>
                                                                                    <div className={`progress-bar ${leave.hr_approved !== 1 ? leave.hr_approved === null ? "bg-warning" : leave.hr_approved === 2 ? "bg-secondary" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>HR</div>
                                                                                    <div className={`progress-bar ${leave.signed !== 1 ? leave.signed === null ? "bg-warning" : leave.signed === 2 ? "bg-secondary" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>PGO</div>
                                                                                </> : <div className="progress-bar bg-danger" style={{ width: "100%" }}>Cancelled</div>}
                                                                            </div>
                                                                        </Col>
                                                                        <Col className="" xs={12} md={true}>
                                                                            <Row className="mt-1">
                                                                                <Col>
                                                                                    <button className="btn btn-sm btn-primary" data-key={leave.id} onClick={(e) => {
                                                                                        setShowLeaveID(leave.id);
                                                                                        openLeaveModal();
                                                                                    }}>
                                                                                        View
                                                                                    </button>
                                                                                    {true ?
                                                                                        <PrintIcon className="ms-3" role="button" onClick={(e) => {
                                                                                            openPrint(leave.id);
                                                                                        }} /> : null}
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                );
                                                            })
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>

            {pages > 1 ?
                <Row className="m-2">
                    <Col className="text-center text-light">
                        {page > 1 ? <Button className="" size='sm' onClick={(e) => {
                            setPage(page => page - 1);
                        }}>{'<'}</Button> : null}
                        <Form.Label className="ms-2 me-2">{`Pages ${page} of ${pages}`}</Form.Label>
                        {page === pages ? "" : <Button className="" size='sm' onClick={(e) => {
                            setPage(page => page + 1);
                        }}>{'>'}</Button>}
                    </Col>
                </Row>
                : ''}

            <Modal show={showLedger} onHide={closeLedger} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <div className="fw-bold">Leave Ledger</div>
                </Modal.Header>
                <Modal.Body>
                    <LeaveLedger
                        leaveData={ledger}
                    />
                </Modal.Body>
            </Modal>

            {/*create modal*/}
            <Modal dialogClassName="leave-application-print-modal" show={showCreate} onHide={closeCreate} size="lg">
                <LeaveApplicationCreate
                    user={props.user}
                    closeFetchCreate={closeFetchCreate}
                />
            </Modal>

            {/*edit modal*/}

            <Modal dialogClassName="leave-application-view-modal" show={showLeaveModal} onHide={closeLeaveModal}>
                {leaves.find(leave => leave.id === showLeaveID) ?
                    <LeaveApplicationModal
                        showEmpDetails={false}
                        showLeaveBalance={true}
                        showAppFor={false}
                        showLeaveSummary={false}
                        editWhenOffApp={false}
                        editWhenPgoApp={false}
                        editable={{
                            type: true,
                            attachment: true,
                            details: true,
                            noDays: true,
                            incDates: true,
                            commutation: true,
                            meridiem: true,
                            lvBalance: false,
                            appFor: false,
                            offApp: false,
                            hrApp: false,
                            pgoApp: false,
                            cancel: false
                        }}

                        showEdit={showLeaveModal}
                        closeEdit={closeLeaveModal}
                        closeFetchEdit={closeFetchLeaveModal}
                        leave={leaves.find(leave => leave.id === showLeaveID)}
                        user={props.user}
                    /> : <></>}
            </Modal>

            {/*print preview modal*/}

            <Modal dialogClassName="leave-application-print-modal " show={showPrint} onHide={closePrint}>
                <Modal.Header closeButton>
                    <Modal.Title>Leave Application</Modal.Title>
                    <ReactToPrint
                        trigger={() => <Button className="ms-3" variant="primary" >Print</Button>}
                        content={() => componentRef.current}
                    />
                </Modal.Header>
                <Modal.Body className="">
                    <LeaveApplicationLayout
                        closePrint={closePrint}
                        leave={leaves.find(leave => leave.id === showLeaveID)}
                        leaveAppID={showLeaveID}
                        ref={componentRef}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}